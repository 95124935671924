
.actionLink {
  color: blueviolet;
  cursor: pointer;
}

@media only screen and (min-width: 797px) and (max-width: 1167px) {
  .vote-post-container {
    max-width: 797px !important;
  }
}

@media only screen and (max-width: 797px) {
  .vote-post-container {
    max-width: 371px !important;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;