.custom-button {
  width: 100%;
  height: 40px;
  padding: 8px;
  display: inline-block;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  font-size: 14px;
  border-radius: 2px;
  margin: 5px 0;
  outline: 0;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.custom-button.ant-btn-primary {
  background-color: #000;
  color: #fff;
}


.custom-button.ant-btn-primary:hover {
  border: 1px solid transparent;
}

.custom-button .icon {
  float: left;
}

.custom-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.custom-button:disabled,
.custom-button[disabled] {
  border: 1px solid transparent;
  background-color: #eee;
  color: #666666;
}

.custom-button:disabled:hover,
.custom-button[disabled]:hover {
  cursor: not-allowed;
}

ul.member-benefit-list {
  list-style: none;
  padding: 0;
}

.member-benefit-list li > span {
  margin-right: 8px;
}

.member-benefit-list li {
  font-size: 1rem;
  font-weight: 400;
}

.sign-in-form .ant-legacy-form-item label {
  font-weight: 500;
}

@primary-color: #DF242F;@brand-primary: #DF242F;