.userPoint {
  background: #45453f;
  border: #45453f 1px solid;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.pulse {
  width: 15px;
  height: 15px;
  border: 8px solid rgb(0, 153, 255);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #716f42;
  z-index: 10;
  top: -8px;
  left: -8px;
  position: absolute;
}

.dot {
  border: 20px solid #0199ff;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: pulse 2s ease-out;
  -moz-animation: pulse 2s ease-out;
  animation: pulse 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0);
    opacity: 0.1;
  }

  50% {
    transform: scale(0.1);
    opacity: 0.2;
  }

  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
