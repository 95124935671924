.order-payment-form-space {
  .common-divider-line {
    width: calc(100% + 18px);
    position: relative;
    left: -10px;
    margin: 5px 0px;
    border-top: 1px solid #e2e2e2;
  }

  .common-label {
    font-size: 16px;
    font-weight: bold;
  }

  .common-section-card {
    margin: 10px 0px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    background: white;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;