.activity-outer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.activity-item-container {
  display: grid;
  grid: 1fr e('/') 45% 1fr;
  border: '1px solid #f2f2f2';
  padding: '15px';
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@primary-color: #DF242F;@brand-primary: #DF242F;