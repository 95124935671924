.autocomplete-dropdown-container {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px;
  position: absolute;
  z-index: 100;
  width: inherit;
  max-width: 500px;
}

.autocomplete-dropdown-container:empty {
  display: none;
}
