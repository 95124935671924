.catering-customize-page {
  margin: 30px;

  .slide-container {
    // height: 30vh;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;

    .slide-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .page-layout {
    display: flex;
  }

  .common-title {
    margin: 30px 0px;
    font-size: 23px;
    font-weight: bold;
  }

  .common-paragraph {
    font-size: 16px;
  }

  .order-form {
    background: #f54d58;
    border-radius: 25px;
    padding: 20px;
    min-width: 300px;
    max-width: 430px;
    margin: 30px auto;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;