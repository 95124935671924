//common css

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tab-line-remove {
  .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }
}

// Desktop
.point-desktop {
  .common-margin-top-lg {
    margin-top: 30px;
  }

  .common-margin-top-sm {
    margin-top: 15px;
  }

  .common-bold-title {
    font-size: 20px;
    font-weight: bold;
  }

  .my-point-banner {
    background-color: pink;
    border-radius: 5px;
    padding: 20px 30px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);

    .my-point {
      height: 150px;
      width: 30%;
      background: white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    }
  }

  .point-function {
    display: grid;
    grid: 1fr e('/') 1fr 1fr;
    grid-gap: 15px;

    div {
      min-height: 120px;
      border: 1px solid #cccccc;
    }
  }

  .antd-table {
    .ant-table-thead {
      th {
        background: #f8f8f8;
      }

      th:last-child {
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
      }

      th:first-child {
        border-top-left-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
      }
    }
  }
}

//mobile
.point-mobile {
  position: relative;
  z-index: 10;

  .common-margin-top {
    margin-top: 10px;
  }

  .banner {
    padding-top: 20px;
    min-height: 110px;
    background: pink;
    width: 100vw;
    position: relative;
    left: -20px;

    .my-point {
      display: grid;
      grid: 1fr e('/') 1fr 1fr;
      grid-gap: 10px;
      color: white;

      .point-rules {
        border: 1px solid white;
        padding: 0px 6px;
        border-radius: 25px;
        position: relative;
        bottom: -4px;
      }
    }

    .point-functions {
      position: absolute;
      bottom: -30px;
      height: 60px;
      display: grid;
      grid: 1fr e('/') 1fr 1fr;
      grid-gap: 10px;
      padding: 0px 10px;
      width: 100%;

      div {
        background: white;
        border-radius: 15px;
        padding: 10px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .point-item {
    position: relative;
    padding: 10px 0px;
    padding-left: 20px;

    .padding-top {
      padding-top: 5px;
    }

    .dash-line-container {
      position: absolute;
      left: 0px;
      width: 10px;
      margin: auto;
      margin-top: 5px;
      height: calc(100% - 5px);

      .line-dot {
        position: absolute;
        top: 0px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #cccccc;
      }

      .line-dashed {
        height: 100%;
        width: 1px;
        border-left: 1px dashed #cccccc;
      }
    }

    .total {
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    .order-id {
      font-size: 13px;
      color: #717171;
    }
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;