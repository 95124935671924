.homeLogo {
  width: 120px;
  height: 64px;
  background: rgba(255, 255, 255, 0.2);
  float: left;
}

.homeLogo img {
  height: 50px;
}
