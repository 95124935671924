.payment-space {
  .payment-custom-calender {
    display: block !important;

    .am-calendar {
      .content {
        .cell {
          .grey {
            color: black !important;
          }

          .disable {
            color: #bbb !important;
          }
        }
      }
    }
  }

  .card-outer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card-container {
    // width: 336px;
    display: grid;
    grid-gap: 10px;
    grid: 1fr e('/') auto 16px;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    box-shadow: 3px 3px 6px rgba(15, 15, 15, 0.15);
    margin: 8px;
  }

  .common-divider-line {
    width: calc(100% + 18px);
    position: relative;
    left: -10px;
    margin: 5px 0px;
    border-top: 1px solid #e2e2e2;
  }

  .common-label {
    font-size: 16px;
    font-weight: bold;
  }

  .common-section-card {
    margin: 10px 0px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    background: white;
  }
}

.payment-calendar-dropdown {
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: rgba(0, 0, 0, 0.08);
  }

  .ant-picker-cell {
    color: black;
  }

  .ant-picker-cell-in-view {
    color: black;
  }

  .ant-picker-cell-inner {
    font-weight: 800;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;