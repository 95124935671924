.payment-check-box {
  color: red;

  .ant-checkbox-wrapper {
    width: auto !important;
    color: red;
  }
}

.payment-check-box-checked {
  color: black;

  .ant-checkbox-wrapper {
    color: black !important;
  }
}

.savedCards {

  /* border: 1px solid blue; */
  width: 100%;
  height: auto;
}

.savedCards .option {

  /* margin: 10px; */
  width: 100%;
  margin: 8px 0px;

  /* border:1px solid red; */
  background: rgb(248, 248, 248);
  padding: 5px 0px;

  /* box-shadow: 3px 6px 16px rgba(153, 153, 153, 0.2); */
}

.savedCards .option.newcard {
  margin-bottom: 0px;
  background: transparent;
  z-index: 9;
}

.savedCards .option .creditCard img {
  height: 40px;
  width: 69px;
}

.cdInfo {
  background: white;
  padding: 10px 20px;

  /* background: black; */
  padding-top: 60px;
  transform: translateY(-40px);
  border: 1px solid rgb(173, 173, 173);
  border-radius: 10px;
}

.stripe .Checkout {

  /*margin: 0 auto;*/
  margin: 30px 0;

  /* max-width: 600px; */
  box-sizing: border-box;
  padding: 0;
  width: 100%;
}

.stripe label {
  color: #333; /*#6b7c93;letter-spacing: 0.025em;*/
  width: 100%;
}

.add-new-address-btn {
  padding: 8px 16px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #ccc;
}

.stripe-payment-button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding: 0 45px; /*0 14px*/
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  background-color: #df242f;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 0; /*margin-top: 10px;*/
  margin-bottom: 5px;
}

.stripe-payment-button:disabled {
  background-color: #dddddd !important;
}

.stripe-payment-button:hover {
  color: #fff;
  cursor: pointer;
  background-color: rgb(187, 31, 41);
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.stripe input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
  rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripe input::placeholder {
  color: #aab7c4;
}

.stripe input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
  rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripe .StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

@primary-color: #DF242F;@brand-primary: #DF242F;