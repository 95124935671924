.variants-addons-space {
  .shopping-cart {
    background-color: white;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    overflow: hidden;
    height: 0px;
    border-radius: 5px;
  }

  .cart-border-line {
    height: 8px;
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
    position: absolute;
    bottom: 15px;
    width: 100%;
  }

  .shoppingCart-item {
    display: grid;
    grid: 1fr e('/') 80px 1fr;
    grid-gap: 10px;
    padding-bottom: 10px;

    .dish-functional-block {
      display: flex;
      justify-content: flex-end;

      .dish-decrement {
        user-select: 'none';
        border: 1px solid #e8e8e8;
        background-color: #ff4949;
        border-radius: 5px;
        text-align: center;
        color: white;
        cursor: pointer;
        width: 24px;
      }

      .dish-counter {
        text-align: center;
        margin: 0 5px;
        min-width: 20px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .shopping-item-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 16px;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;