.catering-page {
  margin: 30px;

  .slide-container {
    // height: 30vh;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;

    .slide-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .common-button {
    border-radius: 45px;
    height: 60px;
    width: 20%;
    min-width: 240px;
    font-size: 20px;
  }

  .started-button {
    position: absolute;
    right: 50px;
    bottom: -30px;
  }

  .selection-margin-b {
    margin-bottom: 60px;
  }

  .common-title {
    font-size: 24px;
    font-weight: bold;
  }

  .catering-what-we-face-and-resolve {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    align-items: center;

    .we-face-and-resolve-title {
      font-size: 24px;
      font-weight: bold;
      flex: 1;
      float: left;
    }


    .we-face-and-resolve-picture {

      flex: 1;

      img {
        float: right;
        margin-right: 40px;
      }
    }
  }

  .how-it-work-container {
    display: flex;
    flex-wrap: wrap;

    .step-container {
      flex: 1;
    }

    .step-container-inner {
      width: 250px;
      margin: auto;
    }

    .step-image {
      max-width: 500px;
      margin: auto;
      width: 200px;
      height: 200px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .step-title {
      color: #f51d2c;
      font-weight: 500;
      margin: 10px 0px;
    }
    // // .step-description{

    // }
  }

  .company-section {
    position: relative;
    left: -30px;
    width: calc(100% + 60px);
    padding: 80px;
    background-color: #e9f9ff;

    .company-slogan {
      font-size: 36px;
      line-height: 50px;
      text-align: center;
    }

    .company-logo {
      text-align: center;

      img {
        margin: 10px 10px;
      }
    }

    .company-description {
      font-size: 33px;
      line-height: 50px;
      text-align: center;
      margin: 48px 0px;
    }
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;