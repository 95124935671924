.video-modal-content {
  .ant-modal-content {
    background-color: transparent !important;
  }
}

.shipping-banner-collapse {
  background: transparent !important;

  .ant-collapse-header {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .ant-collapse-item {
    border-bottom: 0px !important;
  }

  .ant-collapse-content-box {
    padding-bottom: 0px !important;
  }
}

.shipping-banner-alert {
  padding-right: 0px !important;
}

.market-menu-spin-dot {
  .ant-spin-dot-item {
    background-color: white !important;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;