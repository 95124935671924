.catering-page {
  position: relative;

  .common-selection-container {
    width: 100%;
    min-height: 60px;
    color: #8c8c8c;
    background-color: #ffffff;
    padding: 0px 10px;
    border-radius: 25px;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }

  .common-selection-margin-b {
    margin-bottom: 24px;
  }

  .common-selection-inner-container {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .common-selection-outer-container {
    position: relative;
    min-height: 60px;
    width: 100%;
  }

  .float {
    position: absolute;
    z-index: 5;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  }

  .shopping-cart-item-container {
    margin-bottom: 20px;
    font-size: 15px;
    border-bottom: 1px solid #e6e0c3;
    padding: 10px;
    width: 100%;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    min-height: 121px;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;