.vote-post-space {
  min-height: 100vh;

  .page-container {
    max-width: 1300px;
    padding: 10px 20px;
    margin: auto;
  }

  .vote-post-container {
    margin: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;

    .vote-number-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .vote-number {
        font-size: 20px;
        font-weight: bold;
        background: black;
        color: white;
        padding: 3px;
        height: 33px;
        border-radius: 50%;
        min-width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .post-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }

    // .vote-post-picture {
    //   width: 100%;
    //   padding-bottom: 100%;
    //   background-position: 50% 50%;
    //   background-size: cover;
    //   margin: auto;
    // }
    .button-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;