.vote-post-create-slick-dots {
  // padding-bottom: 20px !important;
  .swiper-pagination {
    bottom: 0px !important;

    .swiper-pagination-bullet-active {
      background: #ff000063 !important;
    }
  }
}

.trash-bin-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 999;
  color: red;
  font-size: 1.2rem;
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  opacity: 0.6;
  border-radius: 25px;
}

.vote-campaign-space {
  min-height: 100vh;

  .page-container {
    max-width: 1300px;
    padding: 10px 20px;
    margin: auto;
  }

  .vote-banner {
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .common-margin-b {
    margin-bottom: 10px;
  }

  .vote-post-container-mobile {
    display: grid !important;
    grid: auto e('/') 50% 50% !important;
    grid-gap: 10px;
    margin: 10px 0px;
  }

  .vote-post-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: auto;
    max-width: 1110px;
  }

  .vote-post-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

    .vote-number-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .vote-number {
        font-size: 20px;
        font-weight: bold;
        background: black;
        color: white;
        padding: 3px;
        height: 33px;
        border-radius: 50%;
        min-width: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .post-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }

    .picture {
      grid-area: 1 e('/') 2;
      width: 100%;
      padding-bottom: 100%;
      background-position: 50% 50%;
      background-size: cover;
      margin: auto;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .display-one-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .display-multiple-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;