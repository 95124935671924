.image-tags-space {
  position: relative;
  z-index: 1000;

  .image-dots-container {
    height: 100%;
    position: absolute;
    width: 15px;
    top: 0px;
  }

  .image-dots {
    height: 10px;
    width: 10px;
    background: white;
    border: 2px solid #ccc;
    position: relative;
    top: calc(50% - 5px);
    border-radius: 50%;
  }

  .text-container {
    margin-left: 20px;
    background: rgba(15, 15, 15, 0.5);
    color: white;
    border-radius: 25px;
    padding: 5px 10px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@primary-color: #DF242F;@brand-primary: #DF242F;